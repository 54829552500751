import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'

const ImpressumPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Impressum' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='4' />
      <div style={{width:"80%", maxWidth: "1080px", margin: "100px auto"}}>
        <h1 style={{color: "#2d3748", textAlign: "center", fontSize: "3rem"}}>Impressum</h1>
        <h2>Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
        <p>Quelle: e-recht24.de</p>
        <Divider space='3' />
        <h2>Haftung für Inhalte</h2>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>
        <p>
          Weder Fabian Jost noch irgendjemand garantiert, dass die auf dieser Seite angezeigten Informationen korrekt, komplett und aktuell sind. Holen Sie sich auch bei anderen Quellen Informationen ein, besonders bei gesundheitlichen Fragen. Die Informationen, Dienste und Angebote die von Fabian Jost ausgehen sind kein Ersatz für ärztliche Behandlung, Beratung und Untersuchungen. Ich, Fabian Jost, empfehle immer fachgerechten, ärztlich-medizinischen Rat einzuholen, wenn Sie Probleme mit Ihrer Gesundheitlichen Verfassung haben, sowie alle damit in Verbindung stehenden Behandlungsstadien. Prüfen Sie die Informationen die auf dieser Seite gelistet werden kritisch und konsultieren Sie im Zweifel ihren Arzt oder Apotheker.
        </p>
        <p>
          Haftung für Links Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </p>
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
        <p>
          <b>Impressum Angaben gemäß § 5 TMG:</b><br/>
          Fabian Jost, Martin-Luther-Str. 6, 95679 Waldershof, Deutschland<br/>
          Vertreten durch:<br/>
          Fabian Jost<br/>
          Kontakt:<br/>
          Telefon: +49-152-25209976<br/>
          E-Mail: fabian.jost[at]lytevapes.com
        </p>
        <p>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br/>  
          <a href={"https://ec.europa.eu/consumers/odr"}>https://ec.europa.eu/consumers/odr</a> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query impressumSiteBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/consulting", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default ImpressumPage